// Styles are local by default so imports need to use :global around classes
@import "./default/buttons.scss";
@import "./default/forms.scss";
@import "./default/layout.scss";

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  font-family: "Raleway", sans-serif;
  min-height: 100%;
  position: relative;
}

h1 { font-size: theme("fontSize.6xl"); }
h2 { font-size: theme("fontSize.5xl"); }
h3 { font-size: theme("fontSize.4xl"); }
h4 { font-size: theme("fontSize.3xl"); }
h5 { font-size: theme("fontSize.2xl"); }
h6 { font-size: theme("fontSize.xl"); }
h1, h2, h3, h4, h5, h6 { line-height: 1.5; }

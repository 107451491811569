@mixin button-base($active-bg-color: null, $active-text-color: null, $bg-color: null, $text-color: null) {
  $opacity: 1;

  @if($active-bg-color == null or $active-text-color == null) { $opacity: 0.9; }
  @if($active-bg-color == null) { $active-bg-color: $bg-color; }
  @if($active-text-color == null) { $active-text-color: $text-color; }

  background: $bg-color;
  border-radius: theme("borderRadius.sm");
  color: $text-color;
  cursor: pointer;
  padding: theme("spacing.2") theme("spacing.4");
  text-decoration: none;

  :global(.fab), :global(.fas), :global(.svg-inline--fa) {
    color: $text-color;
  }

  &:visited { color: $text-color; }
  &:not(:global(.btn-disabled)) {
    &:active, &:focus, &:hover {
      background: $active-bg-color;
      color: $active-text-color;
      opacity: $opacity;

      :global(.fab), :global(.fas), :global(.svg-inline--fa) {
        color: $active-text-color;
      }
    }
  }
}

@mixin button-dark($color-class, $color) {
  :global(.btn-#{$color-class}) {
    @include button-base(
      $bg-color: $color,
      $text-color: theme("colors.text.light")
    );
  }
  :global(.btn-outline-#{$color-class}) {
    border: theme("borderWidth.sm") solid $color;

    @include button-base(
      $active-bg-color: theme("colors.background.lightAlt"),
      $bg-color: theme("colors.background.light"),
      $text-color: theme("colors.text.default")
    );
  }
}

@mixin button-light($color-class, $color) {
  :global(.btn-#{$color-class}) {
    @include button-base(
      $bg-color: $color,
      $text-color: theme("colors.text.default")
    );
  }
  :global(.btn-outline-#{$color-class}) {
    border: theme("borderWidth.sm") solid $color;

    @include button-base(
      $active-bg-color: theme("colors.background.lightAlt"),
      $bg-color: theme("colors.background.light"),
      $text-color: theme("colors.text.default")
    );
  }
}

:global {
  .trix-content {
    :not(.attachment-gallery) {
      > action-text-attachment,
      > .attachment {
        display: block;
        margin: 20px 0;
        text-align: center;
      }
    }

    .attachment__caption {
      margin-top: 20px;
    }

    .attachment-gallery {
      margin: 20px 0;

      .attachment {
        max-width: 100% !important;
        padding: 0 0.5em;
      }

      &.attachment-gallery--2 {
        > action-text-attachment,
        > .attachment {
          flex-basis: 50%;
        }
      }

      &.attachment-gallery--3 {
        > action-text-attachment,
        > .attachment {
          flex: 1 0 33%;
        }
      }

      &.attachment-gallery--4 {
        > action-text-attachment,
        > .attachment {
          flex-basis: 25%;
        }
      }
    }

    h1 { font-size: theme("fontSize.6xl"); }
    h2 { font-size: theme("fontSize.5xl"); }
    h3 { font-size: theme("fontSize.4xl"); }
    h4 { font-size: theme("fontSize.3xl"); }
    h5 { font-size: theme("fontSize.2xl"); }
    h6 { font-size: theme("fontSize.xl"); }
    ol { list-style: decimal; }
    ul { list-style: disc; }
  }

  trix-editor {
    margin-top: 10px;
  }

  trix-toolbar {
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;

    .trix-button-group {
      margin-bottom: 0;
    }
  }
}

:global {
  a, .btn-link {
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;

    &:visited { color: inherit; }
    &:active, &:focus, &:hover { color: theme("colors.theme"); }
  }

  .bg-dark {
    a, .btn-link {
      &:active, &:focus, &:hover { color: theme("colors.text.light"); }
    }
  }

  button {
    background: none;
    border: none;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font: inherit;
    text-align: inherit;
  }
}

// NOTE: Decide between colors vs using error, primary, secondary, success, theme
@include button-dark("black", theme("colors.black"));
@include button-dark("blue", theme("colors.blue"));
@include button-dark("gray", theme("colors.darkGray"));
@include button-dark("green", theme("colors.green"));
@include button-dark("red", theme("colors.red"));
@include button-dark("theme", theme("colors.theme"));
@include button-dark("white", theme("colors.white"));
@include button-dark("yellow", theme("colors.yellow"));

// NOTE: After other selectors to override
:global {
  .bg-dark {
    .btn-nav {
      color: theme("colors.text.light");

      &:visited { color: theme("colors.text.light"); }
      &:active, &:focus, &:hover { opacity: 0.7; }
    }
  }
  .bg-green, .bg-theme {
    .btn-icon {
      &:active, &:focus, &:hover { color: theme("colors.text.light"); }
    }
  }
  .btn-disabled, .btn-inactive {
    cursor: default;
    opacity: 0.5;
  }
  .btn-group {
    display: inline-flex;
    vertical-align: middle;

    .btn { flex: 1 1 auto; }
    > .btn:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    > .btn:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .btn-icon {
    &:active, &:focus, &:hover { color: theme("colors.theme"); }
  }
  .btn-nav {
    color: theme("colors.text.default");
    font-size: theme("fontSize.xl");
    text-decoration: none;

    &:visited { color: theme("colors.text.default"); }
    &:active, &:focus, &:hover { color: theme("colors.theme"); }
  }
  .btn-sm {
    font-size: 0.875rem;
    padding: theme("spacing.1") theme("spacing.2");
  }
}
